<template>
  <div class="vh-100 overflow-hidden">
    <!-- Header -->
    <div class="header bg-gradient-danger py-lg-5 log--header">
      <div class="separator separator-bottom separator-skew zindex-100 fill-default">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div
      class="sign--container position-absolute vw-100 vh-100 d-flex justify-content-around align-items-center overflow-auto"
    >
      <div class="d-flex flex-column justify-content-around align-items-center">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="d-flex align-items-center mb-2">
            <img src="/img/brand/wring.png" width="60" />
            <p class="my-4 ml-2 px-2 py-1 bg-primary d-inline-block rounded">
              <span class="text-white font-weight-bold h6 text-uppercase">Quikly</span>
            </p>
          </div>
          <div class="text-center">
            <p class="text-white h2 text-capitalize font-weight-bold">
              Intelligent Product Management
            </p>
            <p class="h4 mt--2 text-secondary font-italic font-weight-light">
              For smart automation behavior
            </p>
          </div>
        </div>
        <div class="mt-4 py-3 rounded bg-secondary border-0 mb-0" v-loading="loading">
          <div class="bg-transparent pb-3">
            <div class="text-muted text-center mt-1 mb-2">
              <small>Sign in with</small>
            </div>
            <div data-netlify-identity-menu></div>
            <div class="btn-wrapper text-center">
              <a :href="githubUrl" class="btn btn-neutral btn-icon">
                <span class="btn-inner--icon"
                  ><img src="/img/icons/common/github.svg"
                /></span>
                <span class="btn-inner--text text-dark">Github</span>
              </a>
              <a :href="googleUrl" class="btn btn-neutral btn-icon">
                <span class="btn-inner--icon"
                  ><img src="/img/icons/common/google.svg"
                /></span>
                <span class="btn-inner--text text-primary">Google</span>
              </a>
            </div>
          </div>
          <div class="px-lg-5">
            <div class="text-center text-muted mb-4">
              <small>Or reset your password</small>
            </div>
            <form role="form" @submit.prevent="handleSubmit()">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <base-input
                    alternative
                    name="Email"
                    type="email"
                    required
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                    v-model="email"
                  />
                  <validation-error :errors="apiValidationErrors.email" />
                </div>
              </div>

              <div class="text-center">
                <base-button
                  type="primary"
                  native-type="submit"
                  class="mb-2 mx-5"
                  :class="{ loading_btn: loading === true }"
                  :disabled="!canSentLink"
                >
                  <i v-if="loading" class="el-icon-loading mr-2"></i>
                  Send Password Reset Link
                </base-button>
              </div>
            </form>
          </div>
        </div>
        <div class="row mt-3 w-100">
          <div class="col-6">
            <a href="/login" class="text-light"><small>Go to login page</small></a>
          </div>
          <div class="col-6 text-right">
            <a href="/register" class="text-light"><small>Create new account</small></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  src="https://identity.netlify.com/v1/netlify-identity-widget.js"
></script>

<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    email: "",
    password: "",
    googleUrl: process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "google",
    githubUrl: process.env.VUE_APP_API_URL_PREFIX + "/auth/v1/oauth2/custom/" + "github",
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;

      const user = {
        email: this.email,
      };

      const requestOptions = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      if (this.$isDemo == 1) {
        await this.$notify({
          type: "danger",
          message: "Password reset is disabled in the demo.",
        });
        return;
      }
      try {
        const res = await this.$store.dispatch("forgotPassword", {
          user,
          requestOptions,
        });
        if (res.data && res.data.status == "success") {
          this.email = "";
          this.$notify({
            type: "success",
            message: res.data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            message: res.data.message,
          });
        }
      } catch (error) {
        console.log(error);
        await this.$notify({
          type: "danger",
          message: "We can't find a user with that e-mail address.",
        });
        this.setApiValidation(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    canSentLink() {
      if (this.apiValidationErrors.email) {
        console.log(this.apiValidationErrors.email[0]);
      }
      return this.email.length > 0 && this.apiValidationErrors;
    },
  },
};
</script>
